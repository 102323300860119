<template>
  <b-overlay
    variant="transparent"
    :show="showLoading"
  >
    <b-tab
      id="s3"
      title-item-class="w-25"
    >
      <template #title>
        <b-card
          :img-src="require('@/assets/images/backend/buckets/s3.png')"
          overlay
        />
      </template>
      <b-form @submit.prevent="onSubmit">
        <b-row>
          <b-col
            md="12"
            class="mt-2"
          >
            <!-- form input -->

            <b-form-group
              class="mb-2 mr-1"
              :label="$t('almacenamiento.nameBuck')"
            >
              <b-form-input
                v-model="bucketName"
                class="mr-1"
                autocomplete="new-password"
              />
            </b-form-group>

            <b-form-group
              class="mb-2 mr-1"
              :label="$t('almacenamiento.buckA3')"
            >
              <b-form-input
                v-model="bucketAccessKey"
                class="mr-1"
                autocomplete="new-password"
              />
            </b-form-group>

            <b-form-group
              class="mb-2 mr-1"
              :label="$t('almacenamiento.buckS3')"
            >
              <b-input-group class="mr-1">
                <b-form-input
                  v-model="bucketSecretKey"
                  :type="passwordFieldType"
                  autocomplete="new-password"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    :icon="passwordToggleIcon"
                    class="cursor-pointer"
                    @click="togglePasswordVisibility"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>

            <b-form-group
              class="mb-2 mr-1"
              :label="$t('almacenamiento.buckReg')"
            >
              <b-form-select
                v-model="bucketRegion"
                class="mr-1"
                :options="bucketRegionOptions"
                :placeholder="$t('almacenamiento.selectReg')"
                autocomplete="new-password"
              />
            </b-form-group>
            <b-form-group
              class="mb-2 mr-1"
              label="CloudFront"
            >
              <b-form-checkbox
                v-model="isCloudfront"
                switch
              />
            </b-form-group>
            <b-form-group
              v-if="isCloudfront"
              class="mb-2 mr-1"
              label="URL cloudFront"
            >
              <b-form-input
                v-model="cloudfromUrl"
                autocomplete="new-password"
              />
            </b-form-group>
            <b-form-group
              v-if="isCloudfront"
              class="mb-2 mr-1"
              :label="$t('distributionId')"
            >
              <b-form-input
                v-model="distributionId"
                autocomplete="new-password"
              />
            </b-form-group>
          </b-col>
          <b-col style="text-align: end;">
            <b-button
              type="submit"
              variant="success"
            >
              {{
                $t("dataGeneric.save")
              }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-tab>
  </b-overlay>
</template>

<script>
import {
  BRow,
  BCol,
  BTab,
  BCard,
  BFormGroup,
  BForm,
  BFormInput,
  BFormCheckbox,
  BButton,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'
import { messageError, showToast } from '@/store/functions'

import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { getUserData } from '@/auth/utils'

export default {
  components: {
    BRow,
    BCol,
    BTab,
    BCard,
    BFormGroup,
    BForm,
    BFormInput,
    BFormSelect,
    BButton,
    BFormCheckbox,
    BInputGroup,
    BInputGroupAppend,
    BOverlay,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isCloudfront: false,
      distributionId: '',
      cloudfromUrl: null,
      bucketName: null,
      bucketAccessKey: null,
      bucketSecretKey: null,
      bucketRegion: null,
      bucketRegionOptions: [
        { value: 'S3_EU_WEST_1_AMAZONAWS_COM', text: 'Irlanda (EU)' },
        { value: 'S3_US_EAST_1_AMAZONAWS_COM', text: 'Virgina (EEUU)' },
        { value: 'S3_US_WEST_1_AMAZONAWS_COM', text: 'California (EEUU)' },
        { value: 'S3_US_WEST_2_AMAZONAWS_COM', text: 'Oregón (EEUU)' },
        { value: 'S3_EU_CENTRAL_1_AMAZONAWS_COM', text: 'Frankfurt (EU)' },
        { value: 'S3_AP_NORTHEAST_1_AMAZONAWS_COM', text: 'Tokio' },
        { value: 'S3_AP_NORTHEAST_2_AMAZONAWS_COM', text: 'Seúl' },
        { value: 'S3_AP_SOUTHEAST_1_AMAZONAWS_COM', text: 'Singapur' },
        { value: 'S3_AP_SOUTHEAST_2_AMAZONAWS_COM', text: 'Sidney' },
        { value: 'S3_SA_EAST_1_AMAZONAWS_COM', text: 'Sao Pablo' },
      ],
      showLoading: false,
    }
  },

  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeOffIcon' : 'EyeIcon'
    },
  },
  mounted() {
    this.getS3PlatformData()
  },
  methods: {
    getS3PlatformData() {
      const userData = getUserData()
      const query = `
        query {
          client(id: "${userData.profile.client.id}"){
            id
            name
            bucketName
            bucketAccessKey
            bucketSecretKey
            bucketRegion
            isCloudfront
            cloudfromUrl
            distributionId
          }
        }
      `
      axios.post('', { query }).then(res => {
        messageError(res, this)

        this.bucketName = res.data.data.client.bucketName
        this.bucketAccessKey = res.data.data.client.bucketAccessKey
        this.bucketSecretKey = res.data.data.client.bucketSecretKey
        this.bucketRegion = res.data.data.client.bucketRegion
        this.isCloudfront = res.data.data.client.isCloudfront
        this.cloudfromUrl = res.data.data.client.cloudfromUrl
        this.distributionId = res.data.data.client.distributionId
      })
    },
    onSubmit(event) {
      this.showLoading = true
      event.preventDefault()
      const userData = getUserData()
      const query = `
        mutation($id: ID!, $bucketName: String,$distributionId: String, $bucketAccessKey: String,$isCloudfront: Boolean, $cloudfromUrl: String, $bucketSecretKey: String, $bucketRegion: ClientBucketRegionInput ){
          updateClient(id: $id, input: {
            bucketType: AS3,
            bucketName : $bucketName,
            bucketAccessKey: $bucketAccessKey,
            bucketSecretKey: $bucketSecretKey,
            bucketRegion: $bucketRegion,
            isCloudfront: $isCloudfront,
            cloudfromUrl: $cloudfromUrl,
            distributionId: $distributionId
          }){
            client {
              id
              bucketType
              bucketName
              isCloudfront
              bucketSecretKey
              bucketRegion
            }
          }
        }
      `
      const variables = {
        id: userData.profile.client.id,
        bucketName: this.bucketName,
        bucketAccessKey: this.bucketAccessKey,
        bucketSecretKey: this.bucketSecretKey,
        bucketRegion: this.bucketRegion,
        isCloudfront: this.isCloudfront,
        cloudfromUrl: this.cloudfromUrl,
        distributionId: this.distributionId,
      }
      const dataForm = new FormData()
      dataForm.append('query', query)
      dataForm.append('variables', JSON.stringify(variables))
      axios
        .post('', dataForm)
        .then(res => {
          messageError(res, this)
          showToast(
            this.$t('success'),
            1, this,
          )
        })
        .catch(() => {
          showToast(this.$t('error'), 2, this)
        })
      this.showLoading = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/bootstrap-extended/include";
@import "~@core/scss/base/components/variables-dark";

.nav-link .card {
  background-color: #f6f6f6 !important;
}

.nav-link.active .card {
  background-image: linear-gradient(47deg, #7367f0, #9e95f5) !important;
}
</style>
