<template>
  <section id="-bucket">
    <b-row class="mb-2">
      <b-col>
        <h1>{{ $t("almacenamiento.data") }}</h1>
        <h5 class="text-primary">
          {{ $t("almacenamiento.infoData") }}
        </h5>
      </b-col>
    </b-row>
    <b-row class="match-height mt-2">
      <b-col>
        <b-card>
          <b-tabs v-model="tabIndex">
            <settings-tabs-bucket-type-s3 :is-active="platform.bucketType === 'AS3' ? true : false" />
            <!-- MICROSOFT AZURE -->
            <!-- <settings-tabs-bucket-type-azure
              :is-active="platform.bucketType === 'AZU' ? true : false"
            /> -->
            <!-- VIMEO -->
            <!-- <settings-tabs-bucket-type-vimeo
              :is-active="platform.bucketType === 'VIM' ? true : false"
            /> -->
            <!-- ODMEDIA -->
            <settings-tabs-bucket-type-odmedia
              :is-active="platform.bucketType === 'ODM' ? true : false"
            />
            <!--:isActive="data.bucketType === 'ODM'? true: false"-->
          </b-tabs>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { getUserData } from '@/auth/utils'
import {
  BRow,
  BCol,
  BCard,
  BTabs,
} from 'bootstrap-vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

import axios from '@axios'
import { messageError } from '@/store/functions'

import Ripple from 'vue-ripple-directive'
import SettingsTabsBucketTypeS3 from './components/buckets/SettingsTabsBucketTypeS3.vue'
/* import SettingsTabsBucketTypeAzure from './components/buckets/SettingsTabsBucketTypeAzure.vue'
import SettingsTabsBucketTypeVimeo from './components/buckets/SettingsTabsBucketTypeVimeo.vue' */
import SettingsTabsBucketTypeOdmedia from './components/buckets/SettingsTabsBucketTypeOdmedia.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BTabs,
    SettingsTabsBucketTypeS3,
    /* SettingsTabsBucketTypeAzure,
    SettingsTabsBucketTypeVimeo, */
    SettingsTabsBucketTypeOdmedia,
  },

  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      bucketTypes: ['AS3', 'AZU', 'VIM', 'ODM'],
      tabIndex: 0,
      platform: {
        name: '',
        emailContact: '',
        bucketType: '',
      },
    }
  },

  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeOffIcon' : 'EyeIcon'
    },
  },

  mounted() {
    const userData = getUserData()
    if (!userData.isSuperuser) {
      this.$router.push({ name: 'dashboard' })
    }
    const query = `
        query {
          client(id: "${userData.profile.client.id}"){
            id
            bucketType
          }
        }
      `
    axios.post('', { query }).then(res => {
      messageError(res, this)

      this.tabIndex = this.bucketTypes.indexOf(res.data.data.client.bucketType)
    })
  },
  created() {
    const userData = getUserData()
    this.$http
      .post('', {
        query: `{
          client(id: "${userData.profile.client.id}") {
            id
            name
            emailContact
            bucketType
            brigthcoveServer
            brigthcoveSecretCode
          }
        }`,
      })
      .then(r => {
        this.platform = r.data.data.client
      })
  },
  methods: {
    onSubmit(event) {
      event.preventDefault()
      alert(JSON.stringify(this.form))
    },
  },
}

</script>
